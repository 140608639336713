.rct-item {
  width: unset !important;
  line-height: 15.5px !important;

  .rct-item-content {
    white-space: pre;
    display: flex !important;

    //https://stackoverflow.com/a/23981970
    text-shadow: #0004 0px 0px 2px, #0004 0px 0px 2px, #0004 0px 0px 2px, #0004 0px 0px 2px, #0004 0px 0px 2px,
      #0004 0px 0px 2px;

    img {
      width: 31px;
      margin-right: 6px;
    }
  }
}

.spotify-controls {
  display: flex;

  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  > div > span {
    vertical-align: middle;
    margin-right: 5px;
  }

  > * {
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
}
