.spotify-row {
  display: flex;

  span {
    display: inline-block;
  }

  .spotify-icon-container {
    background: black;
    border-radius: 50px;
    height: 30px;
    width: 30px;
    margin-right: 5px;

    .spotify-icon {
      fill: rgb(66, 215, 96);
      font-size: 34px;
      left: -2px;
      position: relative;
      top: -2px;
      display: block;
    }
  }
}
