@import '~antd/dist/antd.less';
@import './SpotifyUser.less';
@import './Home.less';

.emoji {
  margin-left: 3px;
  margin-right: 7px;
}

.container {
  width: 100%;
  padding: 10px;
}

.youtube-iframe {
  width: 100%;
  height: calc(100vw / 16 * 9);
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-left-10 {
  margin-left: 10px;
}

